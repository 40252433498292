import {
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import { makeStyles } from '@material-ui/core/styles';
import { Menu } from '@material-ui/icons';
import * as React from 'react';
import { useState } from 'react';

const useStyles = makeStyles({
  list: {
    width: 250,
  },
  linkText: {
    textDecoration: 'none',
    color: 'black',
  },
});

const SideDrawer = () => {
  const classes = useStyles();
  const [state, setState] = useState({ right: false });

  const toggleDrawer = (anchor, open) => () => {
    setState({ [anchor]: open });
  };

  const sideDrawerList = (anchor) => (
    <div
      className={classes.list}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List component="nav">
        <AnchorLink to="/#who-we-are" stripHash title="Who we are" className={classes.linkText}>
          <ListItem button>
            <ListItemText primary="WHO WE ARE" />
          </ListItem>
        </AnchorLink>
        <AnchorLink to="/#our-services" stripHash title="Our services" className={classes.linkText}>
          <ListItem button>
            <ListItemText primary="OUR SERVICES" />
          </ListItem>
        </AnchorLink>
        <AnchorLink to="/#how-we-work" stripHash title="How we work" className={classes.linkText}>
          <ListItem button>
            <ListItemText primary="HOW WE WORK" />
          </ListItem>
        </AnchorLink>
        <AnchorLink to="/#our-people" stripHash title="Our people" className={classes.linkText}>
          <ListItem button>
            <ListItemText primary="OUR PEOPLE" />
          </ListItem>
        </AnchorLink>
        <AnchorLink to="/#get-in-touch" stripHash title="Get in touch" className={classes.linkText}>
          <ListItem button>
            <ListItemText primary="GET IN TOUCH" />
          </ListItem>
        </AnchorLink>
        <a href="/careers" key="CAREERS" className={classes.linkText}>
          <ListItem button>
            <ListItemText primary="CAREERS" />
          </ListItem>
        </a>
      </List>
    </div>
  );

  return (
    <>
      <IconButton
        edge="start"
        aria-label="menu"
        onClick={toggleDrawer('right', true)}
      >
        <Menu fontSize="large" style={{ color: 'black' }} />
      </IconButton>

      <Drawer
        anchor="right"
        open={state.right}
        onOpen={toggleDrawer('right', true)}
        onClose={toggleDrawer('right', false)}
      >
        {sideDrawerList('right')}
      </Drawer>
    </>
  );
};

export default SideDrawer;
