/* eslint-disable react/no-array-index-key */
import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { SOCIAL_NETWORKS } from './helper';
import './styles.css';

export default () => (
  <Grid item xs={12} className="social-network-content">
    <div className="icons-network">
      {
          SOCIAL_NETWORKS.map(({ imgSocialNetwork, name, linkTo }, i) => (
            <a href={linkTo} key={i}>
              <img src={imgSocialNetwork} alt={name} />
            </a>
          ))
        }
    </div>
    <div className="text-content">
      <Typography variant="body2">
        Nearshore Code © 2021 All rights reserved.
      </Typography>
    </div>
  </Grid>
);
