import imgFacebook from '../../../assets/face.png';
import imgLinkedin from '../../../assets/linked.png';
import imgTwitter from '../../../assets/tweet.png';

const SOCIAL_NETWORKS = [
  { imgSocialNetwork: imgFacebook, name: 'facebook', linkTo: 'https://www.facebook.com/nearshorecode' },
  { imgSocialNetwork: imgLinkedin, name: 'linkedin', linkTo: 'https://twitter.com/nearshorecode' },
  { imgSocialNetwork: imgTwitter, name: 'twitter', linkTo: 'https://www.linkedin.com/company/nearshore-code' },
];

export { SOCIAL_NETWORKS };
