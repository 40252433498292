import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import SocialNetworks from './SocialNetworks';
import text from './footer.json';

import './style.css';

export default function Footer() {
  return (
    <footer className="footer-app">
      <Grid container className="footer-content">
        <Grid item xs={12} className="offices-title">
          <Typography gutterBottom variant="subtitle1">
            {text.keys.offices}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={7} className="locations-content">
          <Grid container className="locations">
            <Grid item xs={12} className="location" data-location="cochabamba">
              <Typography variant="body2">
                {text.cochabamba.address}
              </Typography>
              <Typography variant="body2">
                {text.cochabamba.address2}
              </Typography>
              <Typography variant="body2">
                {text.cochabamba.phone}
              </Typography>
            </Grid>
            <Grid item xs={12} className="location" data-location="la paz">
              <Typography variant="body2">
                {text.lapaz.address}
              </Typography>
              <Typography variant="body2">
                {text.lapaz.address2}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={5}>
          <Grid container className="contacts">
            <Grid item xs={12} className="us">
              <Typography variant="body2">
                {text.us.address}
              </Typography>
              <Typography variant="body2">
                <a href={`tel:${text.us.phone}`}>{text.us.phone}</a>
              </Typography>
            </Grid>
            <Grid item xs={12} className="us">
              <Typography variant="body2">
                {text.us2.address}
              </Typography>
              <Typography variant="body2">
                <a href={`tel:${text.us2.phone}`}>{text.us2.phone}</a>
              </Typography>
            </Grid>
            <Grid item xs={12} className="email">
              <a href={`mailto:${text.keys.email}`}>{text.keys.email}</a>
            </Grid>
            <SocialNetworks />
          </Grid>
        </Grid>
      </Grid>
    </footer>
  );
}
