import React from 'react';
import {
  AppBar,
  Toolbar,
  List,
  ListItem,
  ListItemText,
  Hidden,
} from '@material-ui/core';
import Link from 'gatsby-link';
import { makeStyles } from '@material-ui/core/styles';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import SideDrawer from './SideDrawer';
import logo from '../../assets/logo.png';

const useStyles = makeStyles({
  navbarDisplayFlex: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  navDisplayFlex: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  linkText: {
    textDecoration: 'none',
    color: 'black',
  },
});

const Header = () => {
  const classes = useStyles();

  return (
    <AppBar position="fixed" color="default">
      <Toolbar>
        <div className={classes.navbarDisplayFlex}>
          <Link to="/" style={{ flex: 1 }}>
            <img src={logo} alt="Nearshore Code logo" style={{width: '240px'}}/>
          </Link>
          <Hidden smDown>
            <div style={{ flex: 3 }}>
              <List
                component="nav"
                aria-labelledby="main navigation"
                className={classes.navDisplayFlex}
              >
                <AnchorLink to="/#who-we-are" title="Who we are" stripHash className={classes.linkText}>
                  <ListItem button>
                    <ListItemText primary="WHO WE ARE" />
                  </ListItem>
                </AnchorLink>
                <AnchorLink to="/#our-services" title="Our services" stripHash className={classes.linkText}>
                  <ListItem button>
                    <ListItemText primary="OUR SERVICES" />
                  </ListItem>
                </AnchorLink>
                <AnchorLink to="/#how-we-work" title="How we work" stripHash className={classes.linkText}>
                  <ListItem button>
                    <ListItemText primary="HOW WE WORK" />
                  </ListItem>
                </AnchorLink>
                <AnchorLink to="/#our-people" title="Our people" stripHash className={classes.linkText}>
                  <ListItem button>
                    <ListItemText primary="OUR PEOPLE" />
                  </ListItem>
                </AnchorLink>
                <AnchorLink to="/#get-in-touch" title="Get in touch" stripHash className={classes.linkText}>
                  <ListItem button>
                    <ListItemText primary="GET IN TOUCH" />
                  </ListItem>
                </AnchorLink>
                <a href="/careers" key="CAREERS" className={classes.linkText}>
                  <ListItem button>
                    <ListItemText primary="CAREERS" />
                  </ListItem>
                </a>
              </List>
            </div>
          </Hidden>
          <Hidden mdUp>
            <SideDrawer />
          </Hidden>
        </div>
      </Toolbar>
    </AppBar>
  );
};
export default Header;
